/* primary colors*/
$bright-text-color: white;
$dark-text-color: #0f053c;
$title-color: #aa5be6;
$highlight-text-color: #0ad7d7;
$secondary-text-color: #afafaf;

/* secondary colors*/
$ultra-dark-color: #000000;
$cta-hover-state-color: #d80fdf;
$button-color: #07fed9;
$form-field-color: rgba(166, 223, 234, 0.2);
$form-label-color: rgba(191, 216, 221, 0.8);
$drop-down-color: rgb(147, 190, 200);
$dark-form-field-color: rgba(134, 187, 197, 0.15);
$placeholder-color: rgb(188, 206, 208);

/* background colors*/
$main-background-color: rgba(12, 5, 55);
$opaque-background-color: rgb(19, 3, 55);
$divider-color: rgb(110, 100, 131);
$popup-color: rgb(14, 3, 38);

$scroll-bar-color-one: rgb(179, 107, 252);
$scroll-bar-color-two: rgb(46, 46, 53);
