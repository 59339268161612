@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.signup {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 140%;
    background-color: $opaque-background-color;
    z-index: -1;
  }

  &__background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &__title {
    margin-top: 7rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
    font-size: 2rem;
    color: $title-color;
    letter-spacing: 18px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: 1rem;
    width: 100%;

    @include tablet {
      font-size: 4rem;
      margin-top: 4rem;
      padding-top: 3rem;
      letter-spacing: 30px;
      padding-left: 2rem;
    }
    @include smartphone-landscape {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Biome;
    letter-spacing: 5px;
    margin: 0rem;
    padding-bottom: 10rem;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $bright-text-color;
    font-weight: 200;
    width: 100%;
  }
}

.signup__scroll-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.signup__subtitle-text {
  font-size: 1rem;
  text-align: center;
  line-height: 150%;
  font-weight: 200;
  @include tablet {
    font-size: 1.5rem;
  }
}

.signup__subtitle-text-highlight {
  color: $highlight-text-color;
  font-weight: 700;
}

.signup__bracket-left {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 1.5rem;
  margin-left: 2rem;
  @include tablet {
    font-size: 6rem;
  }
}

.signup__bracket-right {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 2rem;
  margin-left: 1.5rem;
  @include tablet {
    font-size: 6rem;
  }
}

.signup__brackets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}

.signup__form {
  display: flex;
  width: 100%;
  padding-bottom: 0rem;
}

.signup__input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 1rem;
}

.signup__input {
  width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $form-field-color;
  border: 1px solid $highlight-text-color;
  border-radius: 10px;
  margin-bottom: 2rem;
  letter-spacing: 4px;
  color: $bright-text-color;
  padding: 0.6rem 1rem;
  margin: 2rem;
  font-size: 0.6rem;
  @include tablet {
    font-size: 1rem;
  }
  &:hover,
  &:focus {
    background-color: $button-color;
    font-weight: 300;
    color: $cta-hover-state-color;
  }
  &.active {
    background-color: $button-color;
    color: $cta-hover-state-color;
  }
}

.signup__button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  padding: 0rem;
  padding-bottom: 3rem;
}

.signup__button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  margin-top: 1 rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 2rem;
  }
}

.signup__cta-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup__sign-in-button {
  margin-top: 2rem;
  font-size: 1rem;
}

.signup__special-line {
  letter-spacing: 5px;
  font-weight: 200;
  margin-top: 2rem;
  font-size: 0.5rem;
  @include tablet {
    font-size: 0.8rem;
  }
}
