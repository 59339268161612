@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.navbar {
  background-color: $main-background-color;
  padding-bottom: 0rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  font-family: Biome;
}

.navbar__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem 0 2rem;

  &__link {
    text-decoration: none;
    color: white;
    text-align: left;
  }
}

.navbar__text {
  margin: 0;
  color: $bright-text-color;
  font-size: 0.45rem;
  font-weight: 200;
  letter-spacing: 2px;
  font-family: Biome;
  padding: 0.3rem 0.75rem 0.3rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 6px;
  transition: border 0.3s ease-out;
  &:hover {
    border: 1px solid $highlight-text-color;
  }
  @include tablet {
    font-size: 0.5rem;
    padding-bottom: 0.8rem;
    letter-spacing: 3px;
    border: 1px solid transparent;
    border-radius: 0rem;
    border-bottom: 4px solid transparent;
    transition: border 0.3s ease-out;
    &:hover {
      border: 1px solid transparent;
      border-radius: 0rem;
      border-bottom: 4px solid $highlight-text-color;
    }
  }
  @include mid {
    font-size: 0.75rem;
    letter-spacing: 6px;
  }
}

.navbar__text--special1 {
  font-family: Biome;
  color: $bright-text-color;
  font-weight: 400;
  font-size: 0.45rem;
  letter-spacing: 6px;
  display: none;
  @include tablet {
    display: flex;
    font-size: 0.5rem;
  }
  @include mid {
    font-size: 0.8rem;
    letter-spacing: 7px;
  }

  @include smartphone-landscape {
    display: flex;
  }
}

.navbar__mobile-links {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0rem;
  padding: 0rem;
  @include tablet {
    display: none;
  }
}

.navbar__text--special2 {
  display: inline-block;
  font-family: Biome;
  letter-spacing: 6px;
  color: $bright-text-color;
  font-size: 0.45rem;
  margin: 0rem;
  padding-top: 0.2rem;
  padding-bottom: 0.5rem;
  border-bottom: 4px solid transparent;
  transition: border-bottom 0.3s ease-out;
  &:hover {
    border-bottom: 4px solid $highlight-text-color;
  }
  @include smartphone-landscape {
    display: none;
  }
}
