@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.search-choices {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  @include tablet {
    padding: 2rem;
  }

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100vh;
    background-color: $opaque-background-color;
    z-index: -1;
  }

  &__background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &__title {
    margin-top: 6rem;
    padding-top: 1rem;
    font-size: 2rem;
    color: $title-color;
    letter-spacing: 17px;
    display: flex;
    justify-content: center;

    @include tablet {
      font-size: 4rem;
    }
    @include smartphone-landscape {
      margin-top: 2rem;
    }
  }
}

.search-choices__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  padding: 0rem;
}

.search-choices__button {
  display: flex;
  justify-items: center;
  margin-top: 1 rem;
  margin-bottom: 2rem;
  align-items: center;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 2rem;
  }
}
