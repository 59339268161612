@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.signin {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  padding-bottom: 4rem;

  @include tablet {
    padding-bottom: 4rem;
  }

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 150%;
    background-color: $opaque-background-color;
    z-index: -1;
    @include smartphone-landscape {
      height: 220vh;
    }
  }

  &__background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 150vh;
    width: auto;

    z-index: -1;
    opacity: 0;

    transition: opacity 0.5s ease;
    @include smartphone-landscape {
      height: 260vh;
    }
  }

  &__title {
    margin-top: 7rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
    font-size: 2rem;
    color: $title-color;
    letter-spacing: 18px;
    display: flex;
    justify-content: center;
    text-align: center;

    padding-left: 1rem;
    @include tablet {
      font-size: 4rem;
      margin-top: 4rem;
      padding-top: 3rem;
      letter-spacing: 30px;
      padding-left: 2rem;
    }
    @include smartphone-landscape {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $bright-text-color;
    font-weight: 200;
    width: 100%;
    // height: 200vh;
  }
}

.signin__subtitle-text {
  font-size: 1rem;
  text-align: center;
  line-height: 150%;
  font-weight: 200;
  @include tablet {
    font-size: 1.5rem;
  }
  @include smartphone-landscape {
    font-size: 0.5rem;
  }
}

.signin__subtitle-text-highlight {
  color: $highlight-text-color;
  font-weight: 700;
  @include smartphone-landscape {
    font-size: 0.5rem;
  }
}

.signin__bracket-left {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 1.5rem;
  margin-left: 2rem;
  @include tablet {
    font-size: 6rem;
  }
  @include smartphone-landscape {
    font-size: 2rem;
  }
}

.signin__bracket-right {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 2rem;
  margin-left: 1.5rem;
  @include tablet {
    font-size: 6rem;
  }
  @include smartphone-landscape {
    font-size: 2rem;
  }
}

.signin__brackets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  letter-spacing: 5px;
  @include smartphone-landscape {
    padding: 0rem;
  }
}

.signin__form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.signin__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-bottom: 4rem;
  width: 100%;
}

.signin__user-type-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  margin-left: 0rem;
  margin-right: 0rem;

  @include tablet {
    width: 60vw;
  }
  @include smartphone-landscape {
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
}

.signin__userchoice-radio {
  display: none;
}

.signin__userlabel {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $form-field-color;
  border: 1px solid $highlight-text-color;
  border-radius: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
  letter-spacing: 4px;
  color: $highlight-text-color;
  padding: 0.6rem 1rem;
  font-size: 0.6rem;
  width: auto;
  min-width: 50%;
  cursor: pointer;

  @include tablet {
    font-size: 1rem;
    margin: 1rem;
    min-width: 40%;
  }
}

.signin__userchoice-radio:checked + .signin__userlabel {
  background-color: $cta-hover-state-color;
}

.signin__form-field-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  @include tablet {
    justify-content: space-around;

    width: 80%;
  }
}

.signin__form-field {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  margin-bottom: 1rem;
  @include tablet {
    min-width: 70%;
    flex-direction: column;
    justify-content: left;
  }
}

.signin__form-field-label {
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  justify-content: left;
  padding-left: 1rem;
  @include tablet {
    justify-content: left;
  }
}

.signin__text-input {
  width: 100%;
  background-color: $form-field-color;
  border: 1px solid $highlight-text-color;
  border-radius: 10px;
  margin-bottom: 1rem;
  letter-spacing: 4px;
  color: $bright-text-color;
  padding: 0.6rem 1rem;
  padding-right: 2rem;
  font-size: 0.6rem;

  @include tablet {
    font-size: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.signin__button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.signin__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-size: 1.25rem;
  margin-top: 1rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 1.75rem;
  }
}
.signin__error-message-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}

.signin__error-message {
  display: flex;
  justify-content: center;
  color: red;
  margin: 1rem 0;
  text-align: center;
  font-size: 0.8rem;
}

.signin__toggle-password {
  position: absolute;
  top: 59%;
  right: 23px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 25px;
  height: 20px;
  @include tablet {
    top: 65%;
  }
}

.signin__andupdatebutton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-size: 0.8rem;
  margin-top: 1rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 1rem;
  }
}

.signin__button-wrapper-special {
  font-size: 0.6rem;
  padding-bottom: 4rem;
  @include tablet {
    padding-bottom: 6rem;
  }
}

.signin__anddeletebutton {
  font-size: 0.6rem;
}

.signin__delete-success-message {
  display: flex;
  justify-content: center;
  color: $cta-hover-state-color;
  margin: 1rem 0;
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 2px;
}
