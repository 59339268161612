@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.recruiter-signup__title-box {
  display: flex;
  margin-bottom: 12rem;
}
.recruiter-signup {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 0rem;
  z-index: -1;
  @include tablet {
    padding-top: 2rem;
  }

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }

  &__background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100vh;
    z-index: -1;
    background-color: $opaque-background-color;
  }

  &__title {
    margin-top: 8rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    font-size: 1.75rem;
    color: $title-color;
    letter-spacing: 20px;
    display: flex;
    justify-content: center;
    @include tablet {
      font-size: 3rem;
      margin-top: 5rem;
      letter-spacing: 30px;
      padding-left: 2rem;
    }
    @include smartphone-landscape {
      margin-top: 2rem;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Biome;
    letter-spacing: 5px;
    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: $bright-text-color;
    font-weight: 200;
    z-index: 1;
  }
}

.recruiter-signup__subtitle {
  font-size: 1.25rem;
  text-align: center;
  line-height: 150%;
  font-weight: 200;
  letter-spacing: 5px;
  @include tablet {
    font-size: 1.75rem;
  }
}

.recruiter-signup__bracket-left {
  display: flex;
  font-size: 3.5rem;
  color: $highlight-text-color;
  margin-right: 1.5rem;
  margin-left: 2rem;
  @include tablet {
    font-size: 4.5rem;
  }
}

.recruiter-signup__bracket-right {
  display: flex;
  font-size: 3.5rem;
  color: $highlight-text-color;
  margin-right: 2rem;
  margin-left: 1.5rem;
  @include tablet {
    font-size: 4.5rem;
  }
}

.recruiter-signup__brackets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}

.recruiter-signup__button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding: 0rem;
}

.recruiter-signup__button {
  margin-top: 1 rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }
  @include tablet {
    font-size: 1.5rem;
  }
}

.recruiter-signup__container {
  width: 100vw;
  background-color: $opaque-background-color;
  margin-top: 18rem;
  @include smartphone-landscape {
    margin-top: 12rem;
  }
}

.recruiter-signup__formwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $form-field-color;
  border-radius: 10px;
  padding: 2rem;
  padding-bottom: 13rem;
  gap: 1rem;
  @include smartphone-landscape {
    padding-top: 0rem;
  }
}

.recruiter-signup__form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
.recruiter-signup__columns-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include tablet {
    flex-direction: row;
    font-size: 1.5rem;
  }
}

.recruiter-signup__left-column,
.recruiter-signup__right-column {
  flex: 1;
  padding: 1rem;
}

.recruiter-signup__form-field {
  margin-bottom: 1rem;
}

label {
  color: $highlight-text-color;
  font-family: "Biome", sans-serif;
}

.recruiter-signup__input {
  width: 100%;
  padding: 0.5rem;
  font-family: "Biome", sans-serif;
  margin-top: 0.3rem;
  margin-bottom: 0.4rem;
  border-radius: 10px;
  border: none;
  background-color: $form-label-color;
}

textarea {
  height: 9.2rem;
}

.recruiter-signup__error-message {
  color: red;
  margin-top: 0.5rem;
}

.recruiter-signup__login-link-wrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  @include tablet {
    margin-bottom: 8rem;
  }
}
.recruiter-signup__login-link-terms {
  color: $highlight-text-color;
  font-size: 0.6rem;
  text-decoration: none;
  font-style: none;
  margin-top: 3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  letter-spacing: 4px;
  font-weight: 200;
  line-height: 180%;
  @include tablet {
    flex-direction: row;
    font-size: 0.75rem;
    margin-left: 7rem;
    margin-right: 7rem;
    margin-bottom: 4rem;
  }
}
.recruiter-signup__login-link {
  color: $highlight-text-color;
  font-size: 1.2rem;
  text-decoration: none;
  font-style: none;
  margin-top: 1rem;
  letter-spacing: 3px;
  font-weight: 300;
}

.recruiter-signup__login-link-special {
  color: $highlight-text-color;
  font-size: 1.2rem;
  text-decoration: none;
  font-style: none;
  margin-top: 2rem;
  margin-left: 0.5rem;
  letter-spacing: 3px;
  &:hover {
    color: $cta-hover-state-color;
  }
}

.recruiter-signup__success-message {
  display: flex;
  justify-content: center;
  color: $cta-hover-state-color;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.recruiter-signup__columns-box-two {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
