@font-face {
    font-family: Biome;
    src: url("../assets/fonts/BiomeW04Regular.otf") format("truetype");
    font-style: normal;
    font-weight: 400;
  }
  
  @font-face {
    font-family: Biome;
    src: url("../assets/fonts/BiomeW01Bold.otf") format("truetype");
    font-style: bold;
    font-weight: 700;
  }

  @font-face {
    font-family: Biome;
    src: url("../assets/fonts/BiomeW04Light.otf") format("truetype");
    font-style: light;
    font-weight: 200;
  }
  
$regular-font: "BiomeW04Regular";
$thin-font: "BiomeW04Light"; 
$bold-font: "BiomeW01Bold";



  
html {
    font-size: 100%;

  }
  p,
  h6 {
    font-size: 0.875rem;
  }
  h5 {
    font-size: 0.875rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 0.875rem;
  }
  h2 {
    font-size: 1rem;
  }
  h1 {
    font-size: 2rem;
  }