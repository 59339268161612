@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.modal__scroll-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $scroll-bar-color-one $scroll-bar-color-two;
}
.modal__overlay {
  display: flex;
  position: fixed;
  top: 20.8%;
  left: 10%;
  right: 10%;

  cursor: pointer;
  z-index: 10;

  @include tablet {
    position: absolute;
    top: 13%;
    left: 10%;
    right: 10%;
  }

  @include mid {
    position: absolute;
    top: 18%;
    left: 6%;
    right: 6%;
  }
  @include desktop {
    position: absolute;
    top: 20.5%;
    left: 7%;
    right: 7%;
  }
}

.modal__content {
  position: relative;
  color: $bright-text-color;
  border: 1px solid $highlight-text-color;
  background-color: $popup-color;
  max-width: 100%;
}

.modal__body {
  max-height: 225px;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $scroll-bar-color-one $scroll-bar-color-two;
  @include tablet {
    max-height: 225px;
  }
  @include mid {
    max-height: 300px;
  }
  @include desktop {
    max-height: 210px;
  }
  @include smartphone-landscape {
    max-height: 115px;
  }
}
.modal__job-logo-wrapper {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  background-color: $title-color;
  @include smartphone-landscape {
    width: 20px;
    height: 20px;
  }
}

.modal__job-logo {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  background-color: $title-color;
  @include smartphone-landscape {
    width: 20px;
    height: 20px;
  }
}

.modal__jobs-no-logo {
  width: 90px;
  height: 90px;
  background-color: $title-color;
  @include smartphone-landscape {
    width: 20px;
    height: 20px;
  }
}
.modal__text-wrapper {
  padding: 1.5rem;

  @include smartphone-landscape {
    padding-top: 0rem;
  }
}
.modal__text {
  font-weight: 200;
  letter-spacing: 3px;
  line-height: 250%;
  font-size: 0.5rem;
  @include tablet {
    font-size: 0.7rem;
    line-height: 250%;
  }
  @include mid {
    font-size: 0.8rem;
    line-height: 250%;
  }
}

.modal__job-title {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @include tablet {
    font-size: 0.7rem;
    line-height: 250%;
  }
  @include mid {
    font-size: 0.8rem;
    line-height: 250%;
  }
}
.modal__job-company {
  text-transform: uppercase;
  color: $cta-hover-state-color;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @include tablet {
    font-size: 0.7rem;
    line-height: 250%;
  }
  @include mid {
    font-size: 0.8rem;
    line-height: 250%;
  }
}
.modal__job-city {
  text-transform: uppercase;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @include tablet {
    font-size: 0.7rem;
    line-height: 250%;
  }
  @include mid {
    font-size: 0.8rem;
    line-height: 250%;
  }
}
.modal__action-wrapper {
  display: flex;
  justify-content: center;
  padding: 1rem;
  @include tablet {
    padding: 1rem;
  }
  @include smartphone-landscape {
    padding: 0.5rem;
  }
}

.modal__apply-button {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 2rem;
  padding-left: 2rem;
  font-size: 0.4rem;
  width: 18%;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 0.8rem;
    text-align: center;
  }
  @include smartphone-landscape {
    margin-top: 0.5rem;
  }
}

.modal__close-button-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
  text-align: center;
  @include smartphone-landscape {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
}
.modal__close-button {
  display: flex;
  margin-top: 1 rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 0.5rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 0.8rem;
  }
}
