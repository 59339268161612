@use "./typography.scss";
@use "./mixins.scss";

body {
    font-family: Biome;
    
}

.app {
    height: 100vh;
}