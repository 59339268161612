@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.home {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-color: $opaque-background-color;
  }

  &__background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &__title {
    margin-top: 6rem;
    padding-top: 1rem;
    font-size: 2rem;
    color: $title-color;
    letter-spacing: 18px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: 1rem;
    @include tablet {
      font-size: 4rem;
      margin-top: 5rem;
      letter-spacing: 30px;
      padding-left: 2rem;
      margin-bottom: 1rem;
    }
    @include smartphone-landscape {
      margin-top: 2rem;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Biome;
    letter-spacing: 5px;
    margin: 0rem;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;
    color: $bright-text-color;
    font-weight: 200;
    z-index: 1;
  }
}

.home__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}
.home__animation-box {
  display: flex;
  flex-direction: row;
}

.home__button-wrapper {
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.home__get-started-button {
  font-size: 0.6rem;
  margin-top: 1 rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 1.2rem;
  }
  @include mid {
    font-size: 1.75rem;
  }
}

.home__highlighted-text {
  color: $highlight-text-color;
}

.home__mission-statement-text {
  font-size: 0.5rem;
  text-align: center;
  line-height: 150%;
  font-weight: 200;
  @include tablet {
    font-size: 1.5rem;
  }
}

.home__tech-companies {
  color: $highlight-text-color;
  font-weight: 700;
}

.home__bracket-left {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 1.5rem;
  margin-left: 2rem;
  @include tablet {
    font-size: 6rem;
  }
}

.home__bracket-right {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 2rem;
  margin-left: 1.5rem;
  @include tablet {
    font-size: 6rem;
  }
}

.home__brackets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  @include desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.animation-left {
  display: none;

  @include desktop {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 3rem;
    margin-right: 0rem;
    width: 130px;
    height: 130px;
    background-size: cover;
    animation: fadeInOut 0.4s ease-in-out infinite;
    z-index: 2;
    opacity: 0.8;
    transition: opacity 0.4s ease;
  }
}

.animation-right {
  display: none;

  @include desktop {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 0rem;
    margin-right: 3rem;
    width: 130px;
    height: 130px;
    background-size: cover;
    animation: fadeInOut 0.4s ease-in-out infinite;
    z-index: 2;
    opacity: 0.8;
    transition: opacity 0.4s ease;
  }
}

.animation-bottom {
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100px;
  height: 100px;
  background-size: cover;
  animation: fadeInOut 0.4s ease-in-out infinite;
  z-index: 2;
  opacity: 0.8;
  transition: opacity 0.4s ease;
  @include mid {
    width: 130px;
    height: 130px;
  }
  @include desktop {
    display: none;
  }
  @include smartphone-landscape {
    display: none;
  }
}

.home__featured-jobs {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding-bottom: 3rem;
  width: 100%;
  background-color: $opaque-background-color;
}

.home__featured-jobs-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-right: 3rem;
  padding-left: 3rem;
  padding-bottom: 2rem;
  min-height: 60vh;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.5s ease-in;

  &.visible {
    opacity: 1;
  }

  @include tablet {
    flex-direction: column;
    padding-right: 3rem;
    padding-left: 3rem;

    min-height: 25vh;
  }

  @include mid {
    flex-direction: row;
    padding-right: 3rem;
    padding-left: 3rem;
    align-items: stretch;
  }
}

.home__featured-jobs-job {
  background-color: $form-field-color;
  border: 1px solid $highlight-text-color;
  margin: 1rem;
  width: 90%;
  flex: 1;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }
  @include tablet {
    width: 90%;
    justify-content: center;
  }

  @include mid {
    width: 15%;
    flex: 1 1 auto;
  }
}

.home__featured-jobs-title {
  color: $highlight-text-color;
  letter-spacing: 8px;
  font-weight: 200;
  font-size: 0.6rem;
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  @include tablet {
    font-size: 0.8rem;
  }
}

.home__featured-jobs-subtitle {
  color: $highlight-text-color;
  font-weight: 200;
  font-size: 0.5rem;
  letter-spacing: 5px;
  text-align: center;
  padding: 1rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  @include tablet {
    font-size: 0.7rem;
  }
}

.home__featured-jobs-logowrapper {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  background-color: $title-color;
}

.home__featured-jobs-logo {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  background-color: $title-color;
}

.home__featured-jobs-no-logo {
  width: 70px;
  height: 70px;
  background-color: $title-color;
}

.home__featured-jobs-job-title {
  color: $bright-text-color;
  letter-spacing: 3px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.8rem;
}
.home__featured-jobs-company {
  color: $bright-text-color;
  font-weight: 200;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  letter-spacing: 3px;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.home__featured-jobs-date {
  color: $bright-text-color;
  font-weight: 200;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  letter-spacing: 3px;
  font-size: 0.75rem;
  margin-bottom: 0.4rem;
  margin-top: 0.3rem;
}

.home__featured-jobs-city {
  color: $bright-text-color;
  font-weight: 200;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  letter-spacing: 3px;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.home__bottom-text {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 5rem;
  @include tablet {
    flex-direction: row;
    margin-bottom: 15rem;
  }
}

.home__featured-jobs-link {
  color: $highlight-text-color;
  letter-spacing: 5px;
  font-weight: 200;
  font-size: 0.5rem;
  text-align: center;
  @include tablet {
    font-size: 0.7rem;
  }
}

.home__featured-jobs {
  &-subtitle,
  &-list {
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  &-title.visible,
  &-subtitle.visible,
  &-list.visible {
    opacity: 1;
  }
}

.home__featured-jobs-subtitle-wrapper {
  height: 1rem;
}

.home__featured-jobs-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home__featured-jobs-loading {
  display: flex;
  justify-content: center;
  color: $bright-text-color;
  font-size: 0.7rem;
  font-weight: 200;
  letter-spacing: 4px;
  text-align: center;
  animation: flash 0.5s linear infinite;
}

.home__featured-jobs-loading.invisible {
  display: flex;
  justify-content: center;
  color: $bright-text-color;
  font-size: 0.7rem;
  font-weight: 200;
  letter-spacing: 4px;
  text-align: center;
  visibility: hidden;
}
