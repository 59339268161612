/* media queries*/
$tablet-breakpoint: 768px;
$mid-breakpoint: 1000px;
$desktop-breakpoint: 1280px;
$phone-landscape-max-height: 414px;

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin mid {
  @media screen and (min-width: $mid-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin smartphone-landscape {
  @media (max-height: $phone-landscape-max-height) and (orientation: landscape) {
    @content;
  }
}
