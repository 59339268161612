@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.redirect-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 200;
  padding: 20px;
  font-size: 1rem;
  color: $button-color;
  background-color: $main-background-color;
}

.jsearch__searching-text {
  animation: flash 0.4s infinite;
}

.jsearch {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  background-color: $opaque-background-color;

  @include tablet {
    padding: 0rem;
  }

  &__title {
    margin-top: 6rem;
    padding-top: 1rem;
    font-size: 2rem;
    color: $title-color;
    letter-spacing: 17px;
    display: flex;
    justify-content: center;
    z-index: 1;
    padding-left: 1rem;
    @include tablet {
      font-size: 4rem;
      margin-top: 6rem;
      padding-top: 2rem;
    }
    @include smartphone-landscape {
      margin-top: 1rem;
    }
  }

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110vh;
    background-color: $opaque-background-color;
    z-index: 0;
  }

  &__background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease;
    background-color: $opaque-background-color;
  }
}

.jsearch__form {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  color: $bright-text-color;
  z-index: 2;
}
.jsearch__input-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.jsearch__input-label {
  letter-spacing: 2px;
}

.jsearch__input {
  display: flex;
  width: 60%;
  height: 100px;
  background-color: $form-field-color;
  border-radius: 20px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: none;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  letter-spacing: 2px;
  font-size: 0.85rem;
  resize: none;

  &::placeholder {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: $placeholder-color;
    font-family: Biome;
    letter-spacing: 2px;
    font-size: 0.85rem;
    white-space: normal;
    overflow: hidden;
  }
  &:not(:placeholder-shown) {
    color: $bright-text-color;
    letter-spacing: 2px;
    top: 1rem;
    left: 1rem;
    font-size: 0.75rem;
  }
}

.jsearch__button-wrapper {
  display: flex;
  margin-top: 2rem;
  padding-bottom: 10rem;
  justify-content: center;
  text-align: center;
}

.jsearch__button {
  display: flex;
  margin-top: 1 rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 2rem;
  }
}
.jsearch__no-jobs-line {
  font-weight: 200;
  padding-top: 1rem;
  padding-left: 3rem;
  padding-bottom: 2rem;
  letter-spacing: 2px;
  border-bottom: 1px solid $divider-color;
}

.jsearch__no-ext-jobs-line {
  font-weight: 200;
  padding-top: 1rem;
  padding-left: 3rem;
  padding-bottom: 2rem;
  letter-spacing: 2px;
}

.jsearch__results-box {
  display: flex;
  flex-direction: column;
  min-height: 40%;
  width: 100%;
}

.jsearch__filler {
  background-color: $opaque-background-color;
  height: 500px;
}

.jsearch__results-list {
  display: flex;
  flex-direction: column;
  padding-top: 5.5rem;
  padding-bottom: 7rem;
  color: $bright-text-color;
  background-color: $opaque-background-color;
  letter-spacing: 1px;
  font-family: Biome;
  width: 100%;

  @include tablet {
    padding-bottom: 4rem;
  }
}

.jsearch__local-results-list {
  display: flex;
  flex-direction: column;
  padding-top: 9rem;
  padding-bottom: 3rem;
  color: $bright-text-color;
  background-color: $opaque-background-color;
  letter-spacing: 1px;
  font-family: Biome;
  width: 100%;
  @include tablet {
    font-size: 5rem;
  }
}

.jsearch__result-box-wrapper {
  border-bottom: 1px solid $divider-color;
}

.jsearch__result-box {
  padding: 3rem;
}

.jsearch__co-logo-container {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  background-color: $title-color;
}

.jsearch__co-logo {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  background-color: $title-color;
}

.jsearch__co-placeholder {
  width: 70px;
  height: 70px;
  background-color: $title-color;
}

.jsearch__job-title {
  letter-spacing: 3px;
}

.jsearch__detail {
  font-weight: 100;
  line-height: 220%;
  font-family: Biome;
  letter-spacing: 2px;
}

.jsearch__buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.jsearch__details-button {
  display: flex;
  justify-content: center;
  width: 70%;
  text-decoration: none;
  font-size: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 1rem;
  padding-left: 1rem;
  align-items: flex-start;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 1rem;
    width: 40%;
  }
}

.jsearch__apply-link {
  display: flex;
  justify-content: center;
  width: 30%;
  text-decoration: none;
  font-size: 0.5rem;
  margin-top: 1 rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 1rem;
  }
}

.jsearch__button-back {
  display: flex;
  justify-content: center;
  width: 30%;
  text-decoration: none;
  font-size: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 1rem;
  }
}

.jsearch__detail-extended {
  line-height: 220%;
  font-family: biome;
  font-weight: 200;
  letter-spacing: 3px;
  font-style: light;
  font-size: 0.7rem;
}

.jsearch__local-results-title {
  font-size: 1.25rem;
  background-color: $opaque-background-color;
  color: $title-color;
  margin: 0rem;
  letter-spacing: 3px;
  text-align: center;
  @include tablet {
    font-size: 1.75rem;
  }
}
