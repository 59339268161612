@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.recruiter-update__title-box {
  display: flex;
  margin-bottom: 12rem;
}
.recruiter-update-profile {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $scroll-bar-color-one $scroll-bar-color-two;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem;
  @include tablet {
    padding-top: 2rem;
  }

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $opaque-background-color;
    z-index: -1;
  }

  &__background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100vh;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 2rem;
    padding: 1rem;
    font-size: 1.5rem;
    color: $title-color;
    letter-spacing: 10px;

    @include tablet {
      font-size: 2.5rem;
      margin-top: 8rem;
      letter-spacing: 15px;
      padding-left: 2rem;
    }
    @include smartphone-landscape {
      margin-top: 4rem;
    }
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Biome;
    letter-spacing: 5px;
    margin: 0rem;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: $bright-text-color;
    font-weight: 200;
    width: 100%;
  }
}

.recruiter-update-profile__subtitle-text {
  font-size: 1rem;
  text-align: center;
  justify-content: center;
  line-height: 150%;
  font-weight: 200;
  @include tablet {
    font-size: 1.5rem;
  }
}

.recruiter-update-profile__bracket-left {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 1.5rem;
  margin-left: 2rem;
  @include tablet {
    font-size: 6rem;
  }
}

.recruiter-update-profile__bracket-right {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 2rem;
  margin-left: 1.5rem;
  @include tablet {
    font-size: 6rem;
  }
}

.recruiter-update-profile__brackets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}

.recruiter-update-profile__container {
  width: 100vw;
  margin-top: 25rem;
}

.recruiter-update-profile__formwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $opaque-background-color;
  border-radius: 10px;
  padding: 2rem;
  padding-bottom: 6rem;
  gap: 1rem;
  @include tablet {
    padding-left: 16rem;
    padding-right: 16rem;
  }
}

.recruiter-update-profile__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include tablet {
    padding-bottom: 2rem;
  }
}

.recruiter-update-profile__columns-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include tablet {
    flex-direction: row;
    font-size: 1.5rem;
  }
}

.recruiter-update-profile__left-column,
.recruiter-update-profile__right-column {
  flex: 1;
  padding: 1rem;
}

.recruiter-update-profile__columns-box-two {
  display: flex;
  flex-direction: column;
}

.recruiter-update-profile__form-field {
  margin-bottom: 1rem;
}

.recruiter-update-profile__input,
textarea {
  width: 100%;
  padding: 0.5rem;
  font-family: "Biome", sans-serif;
  margin-top: 0.3rem;
  margin-bottom: 0.4rem;
  border-radius: 10px;
  border: none;
  background-color: $form-label-color;
}

.recruiter-update-profile__label {
  color: $highlight-text-color;
  font-family: "Biome", sans-serif;
  @include tablet {
    font-size: 1rem;
  }
}

.recruiter-update-profile__error-message {
  color: red;
  margin-top: 0.5rem;
}

.recruiter-update-profile__success-message {
  display: flex;
  justify-content: center;
  color: $cta-hover-state-color;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.recruiter-update-profile__button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  padding-bottom: 7rem;
  @include tablet {
    padding-bottom: 3rem;
  }
}

.recruiter-update-profile__button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  margin-top: 1 rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 2rem;
  }
}
