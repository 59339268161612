@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.about {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100vh;
    background-color: $opaque-background-color;
    z-index: -1;
  }

  &__background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &__title {
    margin-top: 7rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    font-size: 2rem;
    color: $title-color;
    letter-spacing: 18px;
    display: flex;
    justify-content: center;
    z-index: 1;

    @include tablet {
      font-size: 4rem;
      margin-top: 6rem;
    }
    @include smartphone-landscape {
      margin-top: 2rem;
    }
  }
}

.about__article-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $opaque-background-color;
  margin-top: 15rem;
  @include tablet {
    margin-top: 18rem;
    padding-bottom: 7rem;
  }
  @include smartphone-landscape {
    margin-top: 8rem;
  }
}

.about__article {
  background-color: $dark-form-field-color;
  padding: 3rem;
  margin: 4rem 4rem 0rem 4rem;
  line-height: 100%;
  color: $bright-text-color;
  font-size: 1.5rem;
  letter-spacing: 3px;
  border-radius: 10px;

  @include tablet {
    line-height: 155%;
  }
}

.about__logo {
  color: $title-color;
  font-size: 2rem;
  letter-spacing: 5px;
  font-size: 1.3rem;
  @include tablet {
    font-size: 2.5rem;
    letter-spacing: 8px;
  }
}

.about__button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding-bottom: 4rem;
  @include tablet {
    padding-bottom: 2rem;
  }
}

.about__button {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 5rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 2rem;
    margin-bottom: 4rem;
  }
}

.about__text {
  font-weight: 200;
  font-size: 0.8rem;
  @include tablet {
    font-size: 0.9rem;
  }
}
