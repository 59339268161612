@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.contact {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding-top: 2rem;
  @include tablet {
    padding: 2rem;
  }

  &__background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    background-color: $opaque-background-color;
  }

  &__background-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &__title {
    margin-top: 8rem;
    padding-top: 1rem;
    font-size: 2rem;
    color: $title-color;
    letter-spacing: 20px;
    display: flex;
    justify-content: center;
    @include tablet {
      font-size: 4rem;
      margin-top: 10rem;
      letter-spacing: 30px;
      padding-left: 2rem;
    }
    @include smartphone-landscape {
      margin-top: 1rem;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Biome;
    letter-spacing: 5px;
    margin: 0rem;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: $bright-text-color;
    font-weight: 200;
    z-index: 1;
  }
}

.contact__button-wrapper {
  margin-top: 1rem;
  padding: 0rem;
}

.contact__get-started-button {
  margin-top: 1 rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 1rem;
  }
}

.contact__highlighted-text {
  color: $highlight-text-color;
}

.contact__mission-statement-text {
  font-size: 0.75rem;
  text-align: center;
  line-height: 150%;
  font-weight: 200;
  @include tablet {
    font-size: 1.5rem;
  }
}

.contact__tech-companies {
  color: $highlight-text-color;
  font-weight: 700;
}

.contact__bracket-left {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 1.5rem;
  margin-left: 2rem;
  @include tablet {
    font-size: 6rem;
  }
}

.contact__bracket-right {
  display: flex;
  font-size: 4rem;
  color: $highlight-text-color;
  margin-right: 2rem;
  margin-left: 1.5rem;
  @include tablet {
    font-size: 6rem;
  }
}

.contact__brackets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}

.contact__link-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__contact-button {
  margin-top: 1 rem;
  letter-spacing: 4px;
  border: 1px solid $highlight-text-color;
  background-color: $form-field-color;
  border-radius: 10px;
  color: $bright-text-color;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 0.5rem;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: $cta-hover-state-color;
    border: 1px solid $highlight-text-color;
    transform: scale(1.03);
  }

  @include tablet {
    font-size: 0.7rem;
  }
}

.contact__info {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $highlight-text-color;
  letter-spacing: 5px;
  font-weight: 200;
  font-size: 0.8rem;
  margin-top: 3rem;
  width: 90%;
  text-align: center;
  @include smartphone-landscape {
    margin-top: 1rem;
  }
}

.contact__details {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 200;
  font-size: 0.5rem;
  color: $highlight-text-color;
}
