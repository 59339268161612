@use "../../styles/typography.scss";
@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/resets.scss";

.footer {
  position: fixed;
  bottom: 0;
  background-color: $main-background-color;
  padding-bottom: 0rem;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: center;
  color: $bright-text-color;
  align-items: center;
  padding: 0.5rem 2rem 0 2rem;
  width: 100%;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: center;
  @include tablet {
    flex-direction: row;
    height: 3.3rem;
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    width: 100%;
  }

  &__left,
  &__right,
  &__center {
    a {
      text-decoration: none;
      margin: 0rem;
      @include tablet {
        margin-bottom: 0.5rem;
        text-align: center;
      }
    }
  }
}

.footer__left-text {
  font-family: Biome;
  color: $bright-text-color;
  font-size: 0.45rem;
  font-weight: 200;
  letter-spacing: 3px;
  margin-top: 0rem;
  @include tablet {
    font-size: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @include mid {
    font-size: 0.75rem;
    letter-spacing: 6px;
  }
}

.footer__center-text {
  font-family: Biome;
  color: $bright-text-color;
  font-size: 0.45rem;
  font-weight: 200;
  letter-spacing: 3px;
  margin-top: 0rem;
  text-align: center;
  @include tablet {
    font-size: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @include mid {
    font-size: 0.75rem;
    letter-spacing: 6px;
  }
}

.footer__right-text {
  font-family: Biome;
  color: $title-color;
  letter-spacing: 6px;
  font-size: 0.45rem;
  margin-top: 0rem;
  @include tablet {
    font-size: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    letter-spacing: 10px;
  }
  @include mid {
    font-size: 0.75rem;
  }
}
